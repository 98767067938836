<div xpoDialog>
    <h1 mat-dialog-title> Developer Tool - Create RFP <xpo-dialog-close-button></xpo-dialog-close-button> </h1>
  
    <div mat-dialog-content>
        <div class="horizontal-container">
            <form class="form-container" [formGroup]="rfpCreateFormGroup">

                <div class="horizontal-container">
                    <mat-form-field floatLabel="always">
                        <mat-label>Negotiating Customer MAD Code</mat-label>
                        <input matInput name="rfpCreateFormGroupFields.NegotiatingCustMadCd" maxlength="11" oninput="this.value = this.value.toUpperCase()" [formControlName]="rfpCreateFormGroupFields.NegotiatingCustMadCd" required />
                    </mat-form-field>
        
                    <mat-form-field floatLabel="always">
                        <mat-label>Primary Location MAD Code</mat-label>
                        <input matInput name="rfpCreateFormGroupFields.PrimaryAcctMadCd" maxlength="11" oninput="this.value = this.value.toUpperCase()" [formControlName]="rfpCreateFormGroupFields.PrimaryAcctMadCd" required />
                    </mat-form-field>

                    <mat-form-field floatLabel="always">
                        <mat-label>Sales Agent</mat-label>
                        <mat-select placeholder="Select item" required xpoSelect [formControlName]="rfpCreateFormGroupFields.SalesAgentId">
                            <mat-option *ngFor="let rfpUser of rfpUsers" [value]="rfpUser.employeeId">{{ rfpUser.employeeFullName }} - ({{ rfpUser.roleType }})</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
                <div class="horizontal-container">
                    <mat-form-field floatLabel="always">
                        <mat-label>Pricing Type</mat-label>
                        <mat-select placeholder="Select item" required xpoSelect [formControlName]="rfpCreateFormGroupFields.PricingTypeCd">
                            <mat-option *ngFor="let pricingType of lookup.pricingTypes$ | async" [value]="pricingType.code"> {{ pricingType.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field floatLabel="always">
                        <mat-label>RFP Type</mat-label>
                        <mat-select placeholder="Select item" required xpoSelect [formControlName]="rfpCreateFormGroupFields.RfpTypeCd">
                            <mat-option *ngFor="let rfpType of rfpTypes" [value]="rfpType.code" [disabled]="disableRfpTypeCode(rfpType)" [matTooltip]="getRfpTypeOptionTooltip(rfpType)" matTooltipPosition="before"> {{ rfpType.name }} </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field floatLabel="always">
                        <mat-label>Area Code</mat-label>
                        <mat-select placeholder="Select item" required xpoSelect [formControlName]="rfpCreateFormGroupFields.AreaCode">
                            <mat-option *ngFor="let area of areaCodes" [value]="area.code">{{ area.code }} - {{ area.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="horizontal-container" *ngIf="showActiveAgreement">
                    <mat-form-field floatLabel="always">
                        <mat-label>Active Agreement(s)</mat-label>
                        <mat-select placeholder="Select item" required xpoSelect [formControlName]="rfpCreateFormGroupFields.ActiveAgreement">
                            <mat-option *ngFor="let agreement of activeAccountAgreements" [value]="agreement">{{ agreement.accountNumber }}-{{ agreement.agreementSequenceNumber | number: '2.0' }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="horizontal-container">
                    <mat-form-field floatLabel="always">
                        <mat-label>Monthly Promise Revenue</mat-label>
                        <span class="inputPrefix">$</span>
                        <input matInput class="inputRight" name="rfpCreateFormGroupFields.RevenuePromise" [formControlName]="rfpCreateFormGroupFields.RevenuePromise" required />
                    </mat-form-field>
        
                    <mat-form-field floatLabel="always">
                        <mat-label>Monthly Potential Revenue</mat-label>
                        <span class="inputPrefix">$</span>
                        <input matInput class="inputRight" name="rfpCreateFormGroupFields.RevenuePotential" [formControlName]="rfpCreateFormGroupFields.RevenuePotential" required />
                    </mat-form-field>
                </div>

                <div class="horizontal-container" *ngIf="isUpdateLocationRfp">
                    <mat-form-field floatLabel="always">
                        <mat-label>Request Sic</mat-label>
                        <mat-select placeholder="Select item" required xpoSelect [formControlName]="rfpCreateFormGroupFields.RequestSicCode">
                            <mat-option *ngFor="let sicCode of sicCodes" [value]="sicCode">{{ sicCode }}</mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field floatLabel="always">
                        <mat-label>Update Location Type</mat-label>
                        <mat-select placeholder="Select item" required xpoSelect [formControlName]="rfpCreateFormGroupFields.UpdateLocationType">
                            <mat-option *ngFor="let locationType of updateLocationTypes" [value]="locationType">{{ locationType}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="horizontal-container">
                    <div>
                        <mat-slide-toggle [formControlName]="rfpCreateFormGroupFields.ThirdPartyLogisticsInd"> 3PL Bid </mat-slide-toggle>
                    </div>
                    <div>
                        <mat-slide-toggle [formControlName]="rfpCreateFormGroupFields.ShipmentFileInd"> Has Shipment File </mat-slide-toggle>
                    </div>
                    <div *ngIf="isRenegotiateRfp">
                        <mat-slide-toggle [formControlName]="rfpCreateFormGroupFields.InCycleInd"> InCycle </mat-slide-toggle>
                    </div>
                    <div *ngIf="isNewOppRfp">
                        <mat-slide-toggle [formControlName]="rfpCreateFormGroupFields.RetailRolloutInd"> Rollout </mat-slide-toggle>
                    </div>
                    <div *ngIf="isLocalRfp">
                        <mat-slide-toggle [formControlName]="rfpCreateFormGroupFields.NassSubmissionInd"> Is a Nass Submission </mat-slide-toggle>
                    </div>
                    <div>
                        <mat-slide-toggle [formControlName]="rfpCreateFormGroupFields.PremiumServiceInd"> Premium Service </mat-slide-toggle>
                    </div>
                </div>

                <div class="horizontal-container" *ngIf="isPremiumService">
                    <mat-form-field floatLabel="always">
                        <mat-label>Premium Service(s)</mat-label>
                        <mat-select placeholder="Select item" required [formControlName]="rfpCreateFormGroupFields.PremiumServiceCodes" multiple>
                            <mat-option *ngFor="let types of premiumServiceTypes" [value]="types.code">{{ types.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field floatLabel="always">
                        <mat-label>Premium Service Executive</mat-label>
                        <mat-select placeholder="Select item" xpoSelect [formControlName]="rfpCreateFormGroupFields.PsaeEmployeeId">
                            <mat-option *ngFor="let premiumUser of premiumServiceUsers" [value]="premiumUser.employeeId">{{ premiumUser.employeeFullName }} - ({{ premiumUser.roleType }})</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <ng-container *ngIf="showThreePlForm()">
                    <form [formGroup]="getThreePlForm()" class="horizontal-container" >
                        <mat-form-field floatLabel="always">
                            <mat-label>3PL MAD Code</mat-label>
                            <input matInput name="rfpCreateFormGroupFields.ThirdPartyLogisticsMadCd" maxlength="11" oninput="this.value = this.value.toUpperCase()" [formControlName]="rfpCreateFormGroupFields.ThirdPartyLogisticsMadCd" required />
                        </mat-form-field>
            
                        <mat-form-field floatLabel="always">
                            <mat-label>3PL Type</mat-label>
                            <mat-select placeholder="Select item" required xpoSelect [formControlName]="rfpCreateFormGroupFields.ThirdPartyLogisticsTypeCd">
                                <mat-option *ngFor="let types of lookup.threePLTypes$ | async" [value]="types.code"> {{ types.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>

                </ng-container>

                <ng-container *ngIf="showRegionForm()">
                    <form [formGroup]="getRegionForm()" class="horizontal-container" >
                        <mat-form-field floatLabel="always">
                            <mat-label>Sales Region Code</mat-label>
                            <mat-select placeholder="Select item" required xpoSelect [formControlName]="rfpCreateFormGroupFields.SalesRegionCd">
                                <mat-option *ngFor="let types of regionCodes" [value]="types.code">{{ types.code }} - {{ types.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
            
                        <mat-form-field floatLabel="always">
                            <mat-label>RDS Employee</mat-label>
                            <mat-select placeholder="Select item" required xpoSelect [formControlName]="rfpCreateFormGroupFields.RdsEmployeeId">
                                <mat-option *ngFor="let types of rdsEmployees" [value]="types.employeeId">{{ types.regionCode }} - {{ types.employeeName }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>

                </ng-container>
            </form>

            <div class="divider-vertical"></div>

            <div>
                <h2>Developer Notes</h2>

                <div style="padding: 8px 16px">
                    <h3>Contracted</h3>
                    <span>To create a Contracted RFP, have <b>ALL</b> of the following settings:</span>
                    <ul class="list-bullet">
                        <li>Monthly Potential Revenue: <span class="inline-code">>={{ dptThreshold }}</span></li>
                        <li>Has Shipment File: <span class="inline-code">True</span></li>
                    </ul>
    
                    <h3 style="margin-top: 24px;">Dynamic</h3>
                    <span>To create a Dynamic RFP, have <b>ANY</b> of the following settings:</span>
                    <ul class="list-bullet">
                        <li>Monthly Potential Revenue: <span class="inline-code"><{{ dptThreshold}}</span></li>
                        <li>Has Shipment File: <span class="inline-code">False</span></li>
                    </ul>

                </div>
            </div>

        </div>

    </div>

    <div mat-dialog-actions>
        <div></div>
        <button mat-flat-button xpoLargeButton xpoButton (click)="createRFP()" [disabled]="!canCreate()"> Create </button>
    </div>

</div>